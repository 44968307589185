<template>
  <v-app id="forgetPassword">
    <v-main
      class="login-panel pt-15"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container fluid class="pt-15">
        <v-snackbar v-model="snackbar" top right color="error">
          {{ textError }}
        </v-snackbar>
        <v-dialog v-model="showDialog" max-width="400px" persistent>
          <v-card flat class="Card-style pa-2">
            <v-col cols="12">
              <h2>Thank You</h2>
              <v-divider class="my-3"></v-divider>
              <p>Email Has Been Sent to Your Respective Account!</p>
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                elevation="0"
                @click="
                  $refs.forgotPasswordForm.reset();
                  showDialog = false;
                "
                to="/"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-col cols="12">
          <v-row class="pt-15" align="center" justify="center">
            <v-card class="form-card mt-10 py-8" width="500">
              <v-col cols="12">
                <v-card-text>
                  <h1
                    class="
                      header-font
                      blue-grey--text
                      text--darken-2
                      font-weight-bold
                      pb-4
                    "
                  >
                    Forgot Password
                  </h1>
                  <h6 class="subtitle-1 pb-4 font-weight-light">
                    Do have an already account?
                    <a href="/">Login</a>
                  </h6>
                  <v-form v-model="isFormValid" ref="loginForm" class="py-3">
                  <loading :active="isLoading" :loader="loader" />
                    <v-text-field
                      v-model.trim="forgotPasswordData.emailAddress"
                      :rules="emailRule"
                      label="Email"
                      outlined
                      required
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      class="px-0 mx-0 btn-loging"
                      block
                      large
                      @click="forgotPassword"
                      :disabled="!isFormValid"
                      >Submit
                    </v-btn>
                    <v-btn
                      color="error"
                      class="px-0 mx-0 btn-loging"
                      block
                      large
                      href="/"
                      >Cancel
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "forgotPassword",
  data() {
    return {
      isLoading: false,
      snackbar: false,
      textError: "",
      loading: false,
      loader: "bars",
      forgotPasswordData: {},
      emailRule: [(v) => !!v || "Enter your Email."],
      showDialog: false,
      isFormValid: false,
      backgroundUrl: "",
    };
  },
  components: {
    Loading,
  },
  mounted(){
    this.backgroundUrl = backgroundUrl;
  },
  methods: {
    clear() {
      this.$refs.forgotPasswordForm.reset();
    },
    async forgotPassword() {
      this.isLoading = true;
      try {
        let res = await authAPI.forgotPassword(this.forgotPasswordData);
          this.isLoading = false;
        if ((this.text = res.responseMessage)) {
          this.showDialog = true;
          location.href = "/";
        }
      } catch (error) {
        // console.log(error.data.messageDetail)
        this.isLoading = false;
        this.snackbar = true;
        this.textError = error.data.messageDetail;
        // this.$root.$emit("SHOW_SNACKBAR", {
        //   text: err.data.messageDetail,
        //   color: "error",
        // });
      }
    },
  },
};
</script>
<style scoped>
.btn-loging {
  margin-bottom: 15px;
}
</style>
